import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Layout } from '../components/layout';

import Seo from '../components/seo';
import { RegisterProcessPage } from '../components/RegisterProcessPage/RegisterProcessPage';

const RegisterProcess = ({ pageContext }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('trans.register-tutorial')} lang={pageContext.language} />
      <RegisterProcessPage />
    </Layout>
  );
};

export default RegisterProcess;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
